import { Box, ButtonGroup, IconButton, VStack } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import { BiDesktop, BiMobile } from 'react-icons/bi'

import { LetterViewer } from '~features/editor/components/LetterViewer'
import { MobileLetterViewer } from '~features/editor/components/MobileLetterViewer'
import { calculateTransformScale } from '~features/public/hooks/useTransformScale'
import { softMatchResponsiveStyles } from '~shared/util/templates'
import { highlightVariablesInHTMLTemplate } from '~utils/htmlUtils'

interface EditorLetterPreviewProps {
  templateContent: string
  hasMobileToggle?: boolean
}
export const EditorLetterPreview = ({
  templateContent,
  hasMobileToggle = false,
}: EditorLetterPreviewProps) => {
  const [highlightedTemplateHtml, setHighlightedTemplateHtml] = useState<
    string | undefined
  >()

  useEffect(() => {
    if (templateContent) {
      const highlightedTemplateHtml =
        highlightVariablesInHTMLTemplate(templateContent)

      setHighlightedTemplateHtml(highlightedTemplateHtml)
    }
  }, [templateContent])

  const boxRef = useRef<HTMLDivElement | null>(null)
  const [boxWidth, setBoxWidth] = useState(0)

  // get width of parent so that LetterViewer is always set to 90% of parent width (or max A4 size)
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (boxRef.current) {
        setBoxWidth(boxRef.current.offsetWidth)
      }
    })
    if (boxRef.current) {
      resizeObserver.observe(boxRef.current)
    }

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  const [isShowMobileView, setIsShowMobileView] = useState(false)

  const displayResponsive = softMatchResponsiveStyles(templateContent)

  return (
    <Box ref={boxRef} height="100%" width="100%" overflow="auto">
      <VStack
        align={'center'}
        w="100%"
        mt={displayResponsive ? 9 : '89px'}
        spacing={4}
      >
        {displayResponsive && hasMobileToggle && (
          <ButtonGroup
            isAttached
            borderRadius="5"
            borderWidth="2px"
            alignItems="center"
            justifyContent="center"
            borderColor="brand.primary.500"
            overflow="hidden"
            height="36px"
          >
            <IconButton
              aria-label="desktop-preview"
              bg={!isShowMobileView ? 'brand.primary.500' : 'none'}
              _hover={{ bg: 'slate' }}
              border="none"
              width="52px"
              borderRadius="none"
              onClick={() => setIsShowMobileView(false)}
            >
              <BiDesktop size="20" />
            </IconButton>
            <IconButton
              aria-label="mobile-preview"
              bg={isShowMobileView ? 'brand.primary.500' : 'none'}
              _hover={{ bg: 'slate' }}
              border="none"
              width="52px"
              borderRadius="none"
              onClick={() => setIsShowMobileView(true)}
            >
              <BiMobile size="20" />
            </IconButton>
          </ButtonGroup>
        )}
        {isShowMobileView && hasMobileToggle && displayResponsive ? (
          <MobileLetterViewer
            isLoading={false}
            html={highlightedTemplateHtml ?? templateContent}
            transformScale={calculateTransformScale(boxWidth)}
          />
        ) : (
          <LetterViewer
            isLoading={false}
            html={highlightedTemplateHtml ?? templateContent}
            transformScale={calculateTransformScale(boxWidth)}
          />
        )}
      </VStack>
    </Box>
  )
}
