import { Box } from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'

import { EditorLetterPreview } from '~features/create/components/EditorLetterPreview'
import { LetterParamMap } from '~shared/dtos/letters.dto'
import { sanitizeHtml } from '~shared/util/html-sanitizer'
import { replaceWithParams, sanitiseLetterParamMap } from '~shared/util/letters'
import { highlightVariablesInHTMLTemplate } from '~utils/htmlUtils'

import { EmptyPreview } from './EmptyPreview'

interface PreviewTemplateProps {
  templateHtml?: string | null
  letterScale?: number
  isPlaceholdersHighlighted?: boolean
  letterParamMap?: LetterParamMap
  hasMobileToggle?: boolean
}

export const PreviewTemplate = ({
  templateHtml,
  letterScale,
  isPlaceholdersHighlighted = false,
  letterParamMap,
  hasMobileToggle = false,
}: PreviewTemplateProps) => {
  let letterHtml = sanitizeHtml(templateHtml ?? '')
  if (templateHtml && letterParamMap)
    letterHtml = replaceWithParams(
      templateHtml,
      sanitiseLetterParamMap(letterParamMap),
    )

  const [highlightedTemplateHtml, setHighlightedTemplateHtml] = useState<
    string | undefined
  >()

  useEffect(() => {
    if (templateHtml && letterParamMap)
      letterHtml = replaceWithParams(
        templateHtml,
        sanitiseLetterParamMap(letterParamMap),
      )
    setHighlightedTemplateHtml(undefined)
    if (isPlaceholdersHighlighted && letterHtml) {
      const highlightedTemplateHtml =
        highlightVariablesInHTMLTemplate(letterHtml)

      setHighlightedTemplateHtml(highlightedTemplateHtml)
    }
  }, [letterHtml, isPlaceholdersHighlighted, letterParamMap])

  return (
    <Box height="100%" width="100%" overflow="auto">
      {letterHtml ? (
        <EditorLetterPreview
          templateContent={highlightedTemplateHtml || letterHtml}
          hasMobileToggle={hasMobileToggle}
        />
      ) : (
        <EmptyPreview
          title={'Letter Preview'}
          subtitle={
            'This is a preview of what recipients see depending on template you selected.'
          }
        />
      )}
    </Box>
  )
}
