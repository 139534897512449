import { ACCEPTED_TEMPLATE_FIELDS_REGEX } from '../constants/regex'

export const getTemplateFields = (html: string): string[] => {
  // returns array of unique valid template fields
  const fields: string[] = []
  let match: RegExpExecArray | null

  while ((match = ACCEPTED_TEMPLATE_FIELDS_REGEX.exec(html)) !== null) {
    if (!fields.includes(match[0].slice(2, -2)))
      fields.push(match[0].slice(2, -2))
  }
  return fields
}

export const parseTemplateField = (field: string): string =>
  field
    .toLowerCase()
    .replace(/&nbsp;/gi, ' ')
    .trim()
    .replace(/ +/g, ' ')
    .replace(/ /g, '_')

export const softMatchResponsiveStyles = (html: string): boolean => {
  // TODO: this function is a somewhat hacky soft match, but there's no straightforward way to match for html styles
  // since users don't have ability to freely edit html, this should suffice for now as the html syntax should not change
  return (
    html.includes('<style>') &&
    html.includes('@media only screen and (max-width: 600px)') &&
    html.includes('</style>')
  )
}
